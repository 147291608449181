import {Inject, Injectable} from '@angular/core';
import {AngularFireStorage} from "@angular/fire/storage";
import {Observable, Subject} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {LoggerService} from '../logger.service';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {generateRandomString} from '../../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  ref: any;
  task: any;
  uploadProgress: Observable<number> = new Observable<number>();
  downloadURL: Observable<string> = new Observable<string>(null);

  constructor(@Inject(DOCUMENT) private document: Document,
              private _afStorage: AngularFireStorage,
              private _authService: AuthenticationService,
              private _logger: LoggerService,) {
  }

  uploadVideo(video: any, path: string, thumbnail) {

  }

  uploadImage(image: any, path: string, parentId?: string, thumbnail?: any) { //eg productImages
    let _unsubscribe: Subject<any> = new Subject<any>();
    //const image = base64ToFile(imageB64);
    return new Promise(async (resolve, reject) => {
      let thumbnailData;
      if(thumbnail) {
        thumbnailData = await this.uploadImage(thumbnail, 'products');
      }

      this._authService.loggedInUser
        .pipe(takeUntil(_unsubscribe))
        .subscribe(loggedInUser => {
          if (loggedInUser) {
            let folder = path;
            path = path + '/' + Math.random().toString(36).substring(2) + '.png';
            this.ref = this._afStorage.ref(path);
            this.task = this.ref.put(image);
            this.uploadProgress = this.task.percentageChanges();
            this._logger.loadingText = `Uploading ... 5%`;
            this.uploadProgress
              .pipe(takeUntil(_unsubscribe))
              .subscribe(percent => {
                if (percent) {
                  this._logger.log(`Uploading ... ${percent}%`, 'upload');
                  this._logger.loadingText = `Uploading ... ${Math.ceil(percent)}%`;
                }
              });

            this.task.snapshotChanges().pipe(
              finalize(() => {
                this.downloadURL = this.ref.getDownloadURL();
                this.downloadURL.subscribe(async url => {
                  if (url) {
                    console.log(this.ref);
                    this._logger.loadingText = 'Loading ...'; // put back default loading text ::
                    _unsubscribe = new Subject<any>();
                    _unsubscribe.complete();
                    /*let imageFile: ImageFile = await this._imageFileApi.create<any>({
                      name: path,
                      url: url,
                      folder: folder,
                      parentId: parentId
                    }).toPromise(); */
                    resolve({
                      imageId: generateRandomString(15),
                      fileName: path,
                      url: url,
                      default: false,
                      thumbnail: thumbnailData
                    });
                  }
                });
              })
            ).subscribe();
          }
        });
    });

  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  public generateVideoThumbnail(videoFile: Blob): Promise<any> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<any>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('canplay', event => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve({blob: this.dataURLtoBlob(canvas.toDataURL()), url: canvas.toDataURL()});
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

}
