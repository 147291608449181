/* tslint:disable */
import {
  Vendor,
  Branch
} from '../index';

declare var Object: any;
export interface TerminalInterface {
  "vendorId": any;
  "tmnHwId": number;
  "branchId"?: any;
  "status"?: number;
  "updatedBy"?: any;
  "updatedAt"?: Date;
  "firmwareVersion"?: string;
  "linkState"?: string;
  "id"?: any;
  vendor?: Vendor;
  branch?: Branch;
}

export class Terminal implements TerminalInterface {
  "vendorId": any;
  "tmnHwId": number;
  "branchId": any;
  "status": number;
  "updatedBy": any;
  "updatedAt": Date;
  "firmwareVersion": string;
  "linkState": string;
  "id": any;
  vendor: Vendor;
  branch: Branch;
  constructor(data?: TerminalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Terminal`.
   */
  public static getModelName() {
    return "Terminal";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Terminal for dynamic purposes.
  **/
  public static factory(data: TerminalInterface): Terminal{
    return new Terminal(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Terminal',
      plural: 'terminals',
      path: 'terminals',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "tmnHwId": {
          name: 'tmnHwId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "firmwareVersion": {
          name: 'firmwareVersion',
          type: 'string'
        },
        "linkState": {
          name: 'linkState',
          type: 'string',
          default: 'DOWN'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
      }
    }
  }
}
