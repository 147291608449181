/* tslint:disable */
import {
  Vendor,
  Transaction,
  DirectDeposit
} from '../index';

declare var Object: any;
export interface VoucherInterface {
  "name": string;
  "description": string;
  "code": string;
  "tnc"?: string;
  "price": number;
  "extraValue": number;
  "occasion"?: string;
  "type": string;
  "startDate"?: Date;
  "endDate"?: Date;
  "status": number;
  "image"?: any;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "category": string;
  "id"?: any;
  "vendorId"?: any;
  vendor?: Vendor;
  transaction?: Transaction[];
  directDeposit?: DirectDeposit[];
}

export class Voucher implements VoucherInterface {
  "name": string;
  "description": string;
  "code": string;
  "tnc": string;
  "price": number;
  "extraValue": number;
  "occasion": string;
  "type": string;
  "startDate": Date;
  "endDate": Date;
  "status": number;
  "image": any;
  "updatedAt": Date;
  "updatedBy": any;
  "category": string;
  "id": any;
  "vendorId": any;
  vendor: Vendor;
  transaction: Transaction[];
  directDeposit: DirectDeposit[];
  constructor(data?: VoucherInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Voucher`.
   */
  public static getModelName() {
    return "Voucher";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Voucher for dynamic purposes.
  **/
  public static factory(data: VoucherInterface): Voucher{
    return new Voucher(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Voucher',
      plural: 'vouchers',
      path: 'vouchers',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "tnc": {
          name: 'tnc',
          type: 'string'
        },
        "price": {
          name: 'price',
          type: 'number',
          default: 0
        },
        "extraValue": {
          name: 'extraValue',
          type: 'number',
          default: 0
        },
        "occasion": {
          name: 'occasion',
          type: 'string',
          default: 'none'
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'topup'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "image": {
          name: 'image',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "category": {
          name: 'category',
          type: 'string',
          default: 'basic'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction[]',
          model: 'Transaction',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'voucherId'
        },
        directDeposit: {
          name: 'directDeposit',
          type: 'DirectDeposit[]',
          model: 'DirectDeposit',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'voucherId'
        },
      }
    }
  }
}
