/* tslint:disable */
import {
  Vendor
} from '../index';

declare var Object: any;
export interface CollectionInterface {
  "vendorId": any;
  "name": string;
  "status": number;
  "createdAt": Date;
  "updatedAt"?: Date;
  "updatedBy": any;
  "image"?: any;
  "products"?: Array<any>;
  "id"?: any;
  vendor?: Vendor;
}

export class Collection implements CollectionInterface {
  "vendorId": any;
  "name": string;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "image": any;
  "products": Array<any>;
  "id": any;
  vendor: Vendor;
  constructor(data?: CollectionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Collection`.
   */
  public static getModelName() {
    return "Collection";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Collection for dynamic purposes.
  **/
  public static factory(data: CollectionInterface): Collection{
    return new Collection(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Collection',
      plural: 'collections',
      path: 'collections',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "image": {
          name: 'image',
          type: 'any'
        },
        "products": {
          name: 'products',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
      }
    }
  }
}
