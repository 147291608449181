import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AppEvent} from './app.event.class';
import {AppEventType} from './app.event.type';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventQueueService {

  private eventBroker = new Subject<AppEvent<any>>();

  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBroker.pipe(filter(event => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBroker.next(event);
  }
}
