/* tslint:disable */
import {
  Vendor,
  Product,
  Subcategory
} from '../index';

declare var Object: any;
export interface CategoryInterface {
  "vendorId": any;
  "name": string;
  "description"?: string;
  "isFeatured"?: boolean;
  "isActive"?: boolean;
  "image": any;
  "ordering"?: number;
  "status": number;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
  vendor?: Vendor;
  product?: Product[];
  subcategory?: Subcategory[];
}

export class Category implements CategoryInterface {
  "vendorId": any;
  "name": string;
  "description": string;
  "isFeatured": boolean;
  "isActive": boolean;
  "image": any;
  "ordering": number;
  "status": number;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  vendor: Vendor;
  product: Product[];
  subcategory: Subcategory[];
  constructor(data?: CategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Category`.
   */
  public static getModelName() {
    return "Category";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Category for dynamic purposes.
  **/
  public static factory(data: CategoryInterface): Category{
    return new Category(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Category',
      plural: 'categories',
      path: 'categories',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "isFeatured": {
          name: 'isFeatured',
          type: 'boolean',
          default: false
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "image": {
          name: 'image',
          type: 'any'
        },
        "ordering": {
          name: 'ordering',
          type: 'number',
          default: 1000
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'categoryId'
        },
        subcategory: {
          name: 'subcategory',
          type: 'Subcategory[]',
          model: 'Subcategory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'categoryId'
        },
      }
    }
  }
}
