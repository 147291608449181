/* tslint:disable */

declare var Object: any;
export interface ImageFileInterface {
  "url"?: string;
  "name"?: string;
  "folder"?: string;
  "parentId"?: string;
  "id"?: any;
}

export class ImageFile implements ImageFileInterface {
  "url": string;
  "name": string;
  "folder": string;
  "parentId": string;
  "id": any;
  constructor(data?: ImageFileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ImageFile`.
   */
  public static getModelName() {
    return "ImageFile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ImageFile for dynamic purposes.
  **/
  public static factory(data: ImageFileInterface): ImageFile{
    return new ImageFile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ImageFile',
      plural: 'imageFiles',
      path: 'imageFiles',
      idName: 'id',
      properties: {
        "url": {
          name: 'url',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "folder": {
          name: 'folder',
          type: 'string'
        },
        "parentId": {
          name: 'parentId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
