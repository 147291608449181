/* tslint:disable */

declare var Object: any;
export interface TelegramRegistrationInterface {
  "clientId": number;
  "telegramUsername": string;
  "displayName"?: string;
  "status"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
}

export class TelegramRegistration implements TelegramRegistrationInterface {
  "clientId": number;
  "telegramUsername": string;
  "displayName": string;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  constructor(data?: TelegramRegistrationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TelegramRegistration`.
   */
  public static getModelName() {
    return "TelegramRegistration";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TelegramRegistration for dynamic purposes.
  **/
  public static factory(data: TelegramRegistrationInterface): TelegramRegistration{
    return new TelegramRegistration(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TelegramRegistration',
      plural: 'telegramRegistrations',
      path: 'telegramRegistrations',
      idName: 'id',
      properties: {
        "clientId": {
          name: 'clientId',
          type: 'number'
        },
        "telegramUsername": {
          name: 'telegramUsername',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
