import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AngularFireAuthGuard} from "@angular/fire/auth-guard";
import {LoginComponent} from "./login/login.component";
import {redirectLoggedInToDashboard} from "../utilities/auth-guard";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationService} from "./authentication.service";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectLoggedInToDashboard},
  }
];

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    CommonModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    AuthenticationService
  ]
})
export class AuthenticationModule {
}
