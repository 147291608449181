/* tslint:disable */
import {
  Vendor,
  Order,
  Branch,
  Transaction
} from '../index';

declare var Object: any;
export interface RefundInterface {
  "vendorId"?: any;
  "profileId": any;
  "transactionId": number;
  "transactionData"?: any;
  "customerData"?: any;
  "refundRemark"?: string;
  "amount": number;
  "branchId": any;
  "branchName"?: string;
  "orderId": number;
  "status": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "createdBy"?: any;
  "updatedBy"?: any;
  "provider": string;
  "paymentMode": string;
  "remark"?: string;
  "id"?: number;
  vendor?: Vendor;
  order?: Order;
  branch?: Branch;
  transaction?: Transaction;
}

export class Refund implements RefundInterface {
  "vendorId": any;
  "profileId": any;
  "transactionId": number;
  "transactionData": any;
  "customerData": any;
  "refundRemark": string;
  "amount": number;
  "branchId": any;
  "branchName": string;
  "orderId": number;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "createdBy": any;
  "updatedBy": any;
  "provider": string;
  "paymentMode": string;
  "remark": string;
  "id": number;
  vendor: Vendor;
  order: Order;
  branch: Branch;
  transaction: Transaction;
  constructor(data?: RefundInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Refund`.
   */
  public static getModelName() {
    return "Refund";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Refund for dynamic purposes.
  **/
  public static factory(data: RefundInterface): Refund{
    return new Refund(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Refund',
      plural: 'refunds',
      path: 'refunds',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "transactionId": {
          name: 'transactionId',
          type: 'number'
        },
        "transactionData": {
          name: 'transactionData',
          type: 'any'
        },
        "customerData": {
          name: 'customerData',
          type: 'any'
        },
        "refundRemark": {
          name: 'refundRemark',
          type: 'string'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "branchName": {
          name: 'branchName',
          type: 'string'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'any'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "provider": {
          name: 'provider',
          type: 'string'
        },
        "paymentMode": {
          name: 'paymentMode',
          type: 'string'
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction',
          model: 'Transaction',
          relationType: 'belongsTo',
                  keyFrom: 'transactionId',
          keyTo: 'id'
        },
      }
    }
  }
}
