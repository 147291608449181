import {ElementRef, Inject, Injectable, Injector, TemplateRef, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import Swal from 'sweetalert2';
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {ToastrService} from "ngx-toastr";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {ToastService} from './toast/toast.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare var $;
@Injectable({
  providedIn: 'root'
})

export class LoggerService {

  alertClosed: boolean = true;
  alertMessage: string = '';
  alertType: string = 'primary';
  alertTitle: string;
  loadingText: string = 'Loading ...';
  _alertSubscription: Subject<any>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild("dangerTpl") dangerTplRef: ElementRef;


  constructor(@Inject(Injector) private injector: Injector, private _snackBar: MatSnackBar, public toastService: ToastService) {
    this._alertSubscription = new Subject<any>();
    this._alertSubscription.pipe(
      debounceTime(5000)
    ).subscribe(() => this.alertClosed = true); // close alert after 5 seconds ::
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  resetLoadingText(): void {
    this.loadingText = 'Loading ... ';
  }
  showAlert(message: string, title?: string, type?: string): void {
    this.log(message);
    this.alertTitle = title;
    this.alertMessage = message;
    this.alertType = type || 'primary';
    this.alertClosed = false;
  }

  log(m: any, h?: string): void {
    if (!environment.production) {
      console.log(h || 'logger', m);
    }
  }

  table(m: any): void {
    if (!environment.production) {
      console.table(m);
    }
  }

  displayMessage(m: any, h?: string, type?: string): void {
    this.log(m, h);

    if (type === 'success-message')
      type = 'success';
    if (typeof m === 'object')
      m = m.message;

    switch (type) {
      case 'error':
        type = 'danger';
        //this.alertMessage = m;
        //const divEl: TemplateRef<any> = this.dangerTplRef.nativeElement;
        this.toastService.show(m, { classname: 'bg-danger text-light', delay: 15000 });
        break;
      case 'success':
        //this.toastrService.success(m);
        this._snackBar.open(m, 'Close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
        break;
      case 'warning':
        this.toastrService.warning(m);
        break;
      case 'info':
        this.toastService.show(m);
        break;
    }

    this.alertTitle = h;
    this.alertMessage = m;
    this.alertType = type || 'primary';
    this.alertClosed = false;
    this._alertSubscription.next(true);
  }


  showSwal(type: string, title?: string, message?: any, customHtml?: string): any {
    this.log(message);
    if (type === 'basic') {
      Swal.fire(title, message);

    } else if (type === 'title-and-text') {
      Swal.fire(title, message);

    } else if (type === 'success-message') {
      Swal.fire(title, message, 'success');

    } else if (type === 'error') {
      if (typeof message === 'object') {
        message = message.message; // make it a string
      }
      Swal.fire(title, message, 'error');
    } else if (type === 'warning-message-and-confirmation') {
      Swal.mixin({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        icon: 'warning',
        showCancelButton: true,
        //confirmButtonClass: 'btn btn-info btn-fill',
        confirmButtonText: 'Yes, delete it!',
        //cancelButtonClass: 'btn btn-danger btn-fill',
        //closeOnConfirm: false,
      }).fire().then(x => {
        return x;
      });

    } else if (type == 'warning-message-and-cancel') {
      Swal.mixin({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No',
      }).fire().then(x => {
        return x;
      });

    } else if (type == 'custom-html') {
      Swal.fire(
        title,
        customHtml,
        'info'
      );

    } else if (type == 'auto-close') {
      Swal.fire({
        title: title,
        text: message,
        timer: 4000,
        showConfirmButton: false
      });
    } else if (type == 'input-field') {
      Swal.mixin({
        title: title,
        html: '<p><input id="input-field" class="form-control">',
        showCancelButton: true,
        allowOutsideClick: false
      }).fire().then(() => {
        return $('#input-field').val();
      });

    }
  }

  exportRecordsFromTable(filename?: string): void {
    filename = filename || 'koboexport.xlxs';
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, filename);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }
}

