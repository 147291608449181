/* tslint:disable */

declare var Object: any;
export interface OfflineDataLogInterface {
  "action": string;
  "transid": number;
  "tmnHwId": number;
  "branchId"?: string;
  "data": any;
  "syncBy"?: any;
  "syncAt"?: Date;
  "id"?: any;
}

export class OfflineDataLog implements OfflineDataLogInterface {
  "action": string;
  "transid": number;
  "tmnHwId": number;
  "branchId": string;
  "data": any;
  "syncBy": any;
  "syncAt": Date;
  "id": any;
  constructor(data?: OfflineDataLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OfflineDataLog`.
   */
  public static getModelName() {
    return "OfflineDataLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OfflineDataLog for dynamic purposes.
  **/
  public static factory(data: OfflineDataLogInterface): OfflineDataLog{
    return new OfflineDataLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OfflineDataLog',
      plural: 'offlineDataLog',
      path: 'offlineDataLog',
      idName: 'id',
      properties: {
        "action": {
          name: 'action',
          type: 'string'
        },
        "transid": {
          name: 'transid',
          type: 'number'
        },
        "tmnHwId": {
          name: 'tmnHwId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "syncBy": {
          name: 'syncBy',
          type: 'any'
        },
        "syncAt": {
          name: 'syncAt',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}
