import { Component, OnInit } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthenticationService} from "../../authentication/authentication.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  toggleChat: boolean = true;
  toggleSingle: boolean = true;
  loggedInUser: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(private authService: AuthenticationService) {
    this.authService.loggedInUser
      .subscribe(loggedInUser => {
        if(loggedInUser) {
          this.loggedInUser.next(loggedInUser);
        }
      })
  }

  ngOnInit(): void {
  }


  togglechatbar() {
    this.toggleChat = !this.toggleChat;
  }
  singleChatWindow() {
    this.toggleSingle = !this.toggleSingle;
  }

}
