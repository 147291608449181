/* tslint:disable */
import {
  Vendor
} from '../index';

declare var Object: any;
export interface FeedInterface {
  "createdAt": Date;
  "description"?: string;
  "type"?: string;
  "image"?: any;
  "status": number;
  "title": string;
  "titleColor"?: string;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "vendorId"?: any;
  "id"?: any;
  vendor?: Vendor;
}

export class Feed implements FeedInterface {
  "createdAt": Date;
  "description": string;
  "type": string;
  "image": any;
  "status": number;
  "title": string;
  "titleColor": string;
  "updatedAt": Date;
  "updatedBy": any;
  "vendorId": any;
  "id": any;
  vendor: Vendor;
  constructor(data?: FeedInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Feed`.
   */
  public static getModelName() {
    return "Feed";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Feed for dynamic purposes.
  **/
  public static factory(data: FeedInterface): Feed{
    return new Feed(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Feed',
      plural: 'feeds',
      path: 'feeds',
      idName: 'id',
      properties: {
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "description": {
          name: 'description',
          type: 'string',
          default: ''
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'news'
        },
        "image": {
          name: 'image',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "titleColor": {
          name: 'titleColor',
          type: 'string',
          default: '#363b37'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
      }
    }
  }
}
