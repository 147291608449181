/* tslint:disable */
import {
  Vendor,
  Branch,
  Product
} from '../index';

declare var Object: any;
export interface ProductInventoryInterface {
  "vendorId": any;
  "productId": any;
  "branchId": any;
  "isOutOfStock": boolean;
  "leadTime": string;
  "dailyLimit": number;
  "updatedBy"?: any;
  "localUpdatedBy"?: any;
  "updatedAt"?: Date;
  "id"?: any;
  vendor?: Vendor;
  branch?: Branch;
  product?: Product;
}

export class ProductInventory implements ProductInventoryInterface {
  "vendorId": any;
  "productId": any;
  "branchId": any;
  "isOutOfStock": boolean;
  "leadTime": string;
  "dailyLimit": number;
  "updatedBy": any;
  "localUpdatedBy": any;
  "updatedAt": Date;
  "id": any;
  vendor: Vendor;
  branch: Branch;
  product: Product;
  constructor(data?: ProductInventoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductInventory`.
   */
  public static getModelName() {
    return "ProductInventory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProductInventory for dynamic purposes.
  **/
  public static factory(data: ProductInventoryInterface): ProductInventory{
    return new ProductInventory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProductInventory',
      plural: 'productInventory',
      path: 'productInventory',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "productId": {
          name: 'productId',
          type: 'any'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "isOutOfStock": {
          name: 'isOutOfStock',
          type: 'boolean',
          default: false
        },
        "leadTime": {
          name: 'leadTime',
          type: 'string'
        },
        "dailyLimit": {
          name: 'dailyLimit',
          type: 'number'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "localUpdatedBy": {
          name: 'localUpdatedBy',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'productId',
          keyTo: 'id'
        },
      }
    }
  }
}
