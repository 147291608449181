/* tslint:disable */
import {
  Vendor,
  Branch
} from '../index';

declare var Object: any;
export interface PrinterInterface {
  "name": string;
  "vendorId": any;
  "ipAddress": string;
  "status": number;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
  "branchId"?: any;
  vendor?: Vendor;
  branch?: Branch;
}

export class Printer implements PrinterInterface {
  "name": string;
  "vendorId": any;
  "ipAddress": string;
  "status": number;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  "branchId": any;
  vendor: Vendor;
  branch: Branch;
  constructor(data?: PrinterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Printer`.
   */
  public static getModelName() {
    return "Printer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Printer for dynamic purposes.
  **/
  public static factory(data: PrinterInterface): Printer{
    return new Printer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Printer',
      plural: 'printers',
      path: 'printers',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "ipAddress": {
          name: 'ipAddress',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
      }
    }
  }
}
