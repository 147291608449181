import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from "../logger.service";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(private loggerService: LoggerService, private spinner: NgxSpinnerService) {
  }

  handleError(error: any) {
    //super.handleError(error);
    this.loggerService.log(error.message, 'errorHandler');
    this.spinner.hide();
  }


}
