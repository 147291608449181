/* tslint:disable */
import {
  Vendor,
  Profile,
  Order,
  Product,
  Branch
} from '../index';

declare var Object: any;
export interface ProductReviewInterface {
  "vendorId": any;
  "productId": any;
  "orderId": number;
  "branchId"?: any;
  "profileId": any;
  "displayName": string;
  "rating": number;
  "message"?: string;
  "createdAt"?: Date;
  "email"?: string;
  "phone"?: string;
  "orderProduct"?: any;
  "images"?: Array<any>;
  "status"?: number;
  "id"?: any;
  vendor?: Vendor;
  profile?: Profile;
  order?: Order;
  product?: Product;
  branch?: Branch;
}

export class ProductReview implements ProductReviewInterface {
  "vendorId": any;
  "productId": any;
  "orderId": number;
  "branchId": any;
  "profileId": any;
  "displayName": string;
  "rating": number;
  "message": string;
  "createdAt": Date;
  "email": string;
  "phone": string;
  "orderProduct": any;
  "images": Array<any>;
  "status": number;
  "id": any;
  vendor: Vendor;
  profile: Profile;
  order: Order;
  product: Product;
  branch: Branch;
  constructor(data?: ProductReviewInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProductReview`.
   */
  public static getModelName() {
    return "ProductReview";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProductReview for dynamic purposes.
  **/
  public static factory(data: ProductReviewInterface): ProductReview{
    return new ProductReview(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProductReview',
      plural: 'productReviews',
      path: 'productReviews',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "productId": {
          name: 'productId',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "rating": {
          name: 'rating',
          type: 'number'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "orderProduct": {
          name: 'orderProduct',
          type: 'any'
        },
        "images": {
          name: 'images',
          type: 'Array&lt;any&gt;'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'productId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
      }
    }
  }
}
