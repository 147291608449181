/* tslint:disable */
import {
  Branch,
  Profile,
  Vendor,
  Order
} from '../index';

declare var Object: any;
export interface DeliveryInterface {
  "branchId": any;
  "branchAddress": any;
  "deliveryAddress": any;
  "notificationEmailStatus"?: number;
  "deliveryData"?: any;
  "partner": string;
  "remarks"?: string;
  "cancellationReason"?: string;
  "deliveryDate": Date;
  "deliveryEpoch": number;
  "deliveryQuote": any;
  "booking"?: any;
  "status": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "profileId": any;
  "orderId": number;
  "vendorId": any;
  "deliveryUpdates"?: Array<any>;
  "cancelledBy"?: any;
  "id"?: number;
  branch?: Branch;
  profile?: Profile;
  vendor?: Vendor;
  order?: Order;
}

export class Delivery implements DeliveryInterface {
  "branchId": any;
  "branchAddress": any;
  "deliveryAddress": any;
  "notificationEmailStatus": number;
  "deliveryData": any;
  "partner": string;
  "remarks": string;
  "cancellationReason": string;
  "deliveryDate": Date;
  "deliveryEpoch": number;
  "deliveryQuote": any;
  "booking": any;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "profileId": any;
  "orderId": number;
  "vendorId": any;
  "deliveryUpdates": Array<any>;
  "cancelledBy": any;
  "id": number;
  branch: Branch;
  profile: Profile;
  vendor: Vendor;
  order: Order;
  constructor(data?: DeliveryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Delivery`.
   */
  public static getModelName() {
    return "Delivery";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Delivery for dynamic purposes.
  **/
  public static factory(data: DeliveryInterface): Delivery{
    return new Delivery(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Delivery',
      plural: 'deliveries',
      path: 'deliveries',
      idName: 'id',
      properties: {
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "branchAddress": {
          name: 'branchAddress',
          type: 'any'
        },
        "deliveryAddress": {
          name: 'deliveryAddress',
          type: 'any'
        },
        "notificationEmailStatus": {
          name: 'notificationEmailStatus',
          type: 'number',
          default: 0
        },
        "deliveryData": {
          name: 'deliveryData',
          type: 'any'
        },
        "partner": {
          name: 'partner',
          type: 'string',
          default: 'lalamove'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "cancellationReason": {
          name: 'cancellationReason',
          type: 'string'
        },
        "deliveryDate": {
          name: 'deliveryDate',
          type: 'Date'
        },
        "deliveryEpoch": {
          name: 'deliveryEpoch',
          type: 'number'
        },
        "deliveryQuote": {
          name: 'deliveryQuote',
          type: 'any'
        },
        "booking": {
          name: 'booking',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "deliveryUpdates": {
          name: 'deliveryUpdates',
          type: 'Array&lt;any&gt;'
        },
        "cancelledBy": {
          name: 'cancelledBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
      }
    }
  }
}
