import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {
  Action,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument, DocumentChangeAction,
  DocumentReference, DocumentSnapshotDoesNotExist, DocumentSnapshotExists
} from "@angular/fire/firestore";
import {map, take, tap} from "rxjs/operators";
import * as firebase from 'firebase'
import 'firebase/firestore'
import {AngularFireAuth} from "@angular/fire/auth";


type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;


@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private _afs: AngularFirestore, private _afAuth: AngularFireAuth) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this._afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this._afs.doc<T>(ref) : ref;
  }

  /// **********************
  /// Get Data ::
  /// **********************

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(map(doc => {
          return {...doc.payload.data(), id: doc.payload.id} as T;
        })
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges()
      .pipe(map(docs => {
          return docs.map(a => {
            return {
              ...a.payload.doc.data(),
              id: a.payload.doc.id
            }
          }) as T[];
        })
      );
  }


  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            const data: Object = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        }),
      );
  }


  /// **************
  /// write data
  /// **************
  get timeStamp() {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }

  set<T>(ref: DocPredicate<T>, data) {
    const timestamp = this.timeStamp;
    return this.doc(ref).set({
      ...data,
      createdAt: timestamp,
      updatedAt: timestamp
    });
  }

  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timeStamp,
    });
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  add<T>(ref: CollectionPredicate<T>, data): Promise<firebase.default.firestore.DocumentReference> {
    const timestamp = this.timeStamp;
    if (!data.id) {
      const id = this._afs.createId();
      data = {id, ...data};
    }
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    return doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

  geopoint(lat: number, lng: number) {
    return new firebase.default.firestore.GeoPoint(lat, lng);
  }


  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((d: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>>) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        }),
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        }),
      )
      .subscribe();
  }

}
