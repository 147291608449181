/* tslint:disable */
import {
  Profile,
  Vendor,
  Order,
  Branch,
  Voucher,
  DirectDeposit,
  Refund
} from '../index';

declare var Object: any;
export interface TransactionInterface {
  "vendorId"?: any;
  "profileId": any;
  "customerData"?: any;
  "branchName"?: string;
  "branchId"?: any;
  "orderId"?: number;
  "type"?: string;
  "isWallet"?: boolean;
  "amount"?: number;
  "taxamount"?: number;
  "nettAmount"?: number;
  "voucherId"?: any;
  "voucherSnapshot"?: any;
  "invoiceId"?: number;
  "costBreakdown"?: any;
  "status": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "invoiceUrl"?: string;
  "paymentMode": string;
  "partner": string;
  "paymentLink"?: string;
  "gatewayRequestData"?: any;
  "transactionData"?: any;
  "transactionDate"?: Date;
  "fulfillmentDate"?: Date;
  "deliveryQuote"?: any;
  "itemTotal": number;
  "isPickUp"?: boolean;
  "gatewayStatus"?: number;
  "payout"?: any;
  "id"?: number;
  profile?: Profile;
  vendor?: Vendor;
  order?: Order;
  branch?: Branch;
  voucher?: Voucher;
  directDeposit?: DirectDeposit[];
  refund?: Refund[];
}

export class Transaction implements TransactionInterface {
  "vendorId": any;
  "profileId": any;
  "customerData": any;
  "branchName": string;
  "branchId": any;
  "orderId": number;
  "type": string;
  "isWallet": boolean;
  "amount": number;
  "taxamount": number;
  "nettAmount": number;
  "voucherId": any;
  "voucherSnapshot": any;
  "invoiceId": number;
  "costBreakdown": any;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "invoiceUrl": string;
  "paymentMode": string;
  "partner": string;
  "paymentLink": string;
  "gatewayRequestData": any;
  "transactionData": any;
  "transactionDate": Date;
  "fulfillmentDate": Date;
  "deliveryQuote": any;
  "itemTotal": number;
  "isPickUp": boolean;
  "gatewayStatus": number;
  "payout": any;
  "id": number;
  profile: Profile;
  vendor: Vendor;
  order: Order;
  branch: Branch;
  voucher: Voucher;
  directDeposit: DirectDeposit[];
  refund: Refund[];
  constructor(data?: TransactionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transaction`.
   */
  public static getModelName() {
    return "Transaction";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transaction for dynamic purposes.
  **/
  public static factory(data: TransactionInterface): Transaction{
    return new Transaction(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transaction',
      plural: 'transactions',
      path: 'transactions',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "customerData": {
          name: 'customerData',
          type: 'any'
        },
        "branchName": {
          name: 'branchName',
          type: 'string'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string',
          default: 'topdown'
        },
        "isWallet": {
          name: 'isWallet',
          type: 'boolean',
          default: false
        },
        "amount": {
          name: 'amount',
          type: 'number',
          default: 0
        },
        "taxamount": {
          name: 'taxamount',
          type: 'number',
          default: 0
        },
        "nettAmount": {
          name: 'nettAmount',
          type: 'number',
          default: 0
        },
        "voucherId": {
          name: 'voucherId',
          type: 'any'
        },
        "voucherSnapshot": {
          name: 'voucherSnapshot',
          type: 'any'
        },
        "invoiceId": {
          name: 'invoiceId',
          type: 'number'
        },
        "costBreakdown": {
          name: 'costBreakdown',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "invoiceUrl": {
          name: 'invoiceUrl',
          type: 'string'
        },
        "paymentMode": {
          name: 'paymentMode',
          type: 'string',
          default: 'wallet'
        },
        "partner": {
          name: 'partner',
          type: 'string',
          default: 'kobo'
        },
        "paymentLink": {
          name: 'paymentLink',
          type: 'string'
        },
        "gatewayRequestData": {
          name: 'gatewayRequestData',
          type: 'any'
        },
        "transactionData": {
          name: 'transactionData',
          type: 'any'
        },
        "transactionDate": {
          name: 'transactionDate',
          type: 'Date'
        },
        "fulfillmentDate": {
          name: 'fulfillmentDate',
          type: 'Date'
        },
        "deliveryQuote": {
          name: 'deliveryQuote',
          type: 'any'
        },
        "itemTotal": {
          name: 'itemTotal',
          type: 'number',
          default: 0
        },
        "isPickUp": {
          name: 'isPickUp',
          type: 'boolean',
          default: false
        },
        "gatewayStatus": {
          name: 'gatewayStatus',
          type: 'number',
          default: 2
        },
        "payout": {
          name: 'payout',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        voucher: {
          name: 'voucher',
          type: 'Voucher',
          model: 'Voucher',
          relationType: 'belongsTo',
                  keyFrom: 'voucherId',
          keyTo: 'id'
        },
        directDeposit: {
          name: 'directDeposit',
          type: 'DirectDeposit[]',
          model: 'DirectDeposit',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'transactionId'
        },
        refund: {
          name: 'refund',
          type: 'Refund[]',
          model: 'Refund',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'transactionId'
        },
      }
    }
  }
}
