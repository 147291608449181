import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";

export function replaceAll(str: string, find: string, replace: string) { // replace all occurences of string
  return str.replace(new RegExp(find, 'g'), replace);
}

export function generateRandomString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function loadImage(size: string, image: any): string {
  if(!image?.fileName) {
    return image.url || image;
  }
  let fileName = decodeURI(image.fileName).split('/').pop();
  image.url = decodeURI(image.url);
  return image.url.replace(fileName, `thumb@${size}_${fileName}`);
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));


export function relocateArrayElement(input: Array<any>, from: number, to: number): Array<any> {
  let numberOfDeletedElm = 1;
  const elm = input.splice(from, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  input.splice(to, numberOfDeletedElm, elm);
  return input;
}

export function liveSearch<T, R>(
  dataCb: (query: T) => Promise<R>,
  delay = 250
) {
  return (source$: Observable<T>) => source$.pipe(
    debounceTime(delay),
    distinctUntilChanged(),
    switchMap(dataCb)
  );
}
