/* tslint:disable */
import {
  Vendor,
  Category,
  Product
} from '../index';

declare var Object: any;
export interface SubcategoryInterface {
  "name": string;
  "description"?: string;
  "categoryId": any;
  "isFeatured"?: boolean;
  "isActive"?: boolean;
  "candles"?: boolean;
  "extraMessage"?: boolean;
  "image": any;
  "ordering"?: number;
  "status": number;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
  "vendorId"?: any;
  vendor?: Vendor;
  category?: Category;
  product?: Product[];
}

export class Subcategory implements SubcategoryInterface {
  "name": string;
  "description": string;
  "categoryId": any;
  "isFeatured": boolean;
  "isActive": boolean;
  "candles": boolean;
  "extraMessage": boolean;
  "image": any;
  "ordering": number;
  "status": number;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  "vendorId": any;
  vendor: Vendor;
  category: Category;
  product: Product[];
  constructor(data?: SubcategoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Subcategory`.
   */
  public static getModelName() {
    return "Subcategory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Subcategory for dynamic purposes.
  **/
  public static factory(data: SubcategoryInterface): Subcategory{
    return new Subcategory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Subcategory',
      plural: 'subcategories',
      path: 'subcategories',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "categoryId": {
          name: 'categoryId',
          type: 'any'
        },
        "isFeatured": {
          name: 'isFeatured',
          type: 'boolean',
          default: false
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "candles": {
          name: 'candles',
          type: 'boolean',
          default: false
        },
        "extraMessage": {
          name: 'extraMessage',
          type: 'boolean',
          default: false
        },
        "image": {
          name: 'image',
          type: 'any'
        },
        "ordering": {
          name: 'ordering',
          type: 'number',
          default: 1000
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'subcategoryId'
        },
      }
    }
  }
}
